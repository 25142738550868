.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.subTitle {
  color: #868e96;
}
.preload-wrapper.finish {
  opacity: 0;
  transition: opacity .5s ease-out;
}

.preload-wrapper {
  height: 100vh;
  background-color: #171c28 ;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
